<template>

  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100 flex-grow">
            <div class="col-xl-4 col-lg-6 mx-auto">
              <div class="auth-form-light p-5">
                <div class="brand-logo text-center">
                  <img src="../../../assets/images/logo-color.png">
                </div>
                <h4 class="text-center">¡Hola! vamos a comenzar</h4>
                <h6 class="font-weight-light text-center">Accede para continuar.</h6>

                <b-alert variant="danger" v-if="error" show>Nombre de usuario o contraseña incorrectos</b-alert>
                <form @submit.prevent="login">
                  <div class="form-group">
                    <input type="text" v-model="email" required id="email" class="form-control form-control-lg" placeholder="Usuario">
                  </div>
                  <div class="form-group">
                    <input type="password" class="form-control form-control-lg" id="password" v-model="password" required placeholder="Contraseña">
                  </div>
                  <div class="mt-3">
                    <submitbutton :label="'Acceder'" :label_loading="'Accediendo...'" :loading_flag="loading" :wide="true"/>
                    <!--<router-link class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" to="/">SIGN IN</router-link>-->
                  </div>
                  <div class="my-2 d-flex justify-content-between align-items-center">
                    <div class="form-check">
                      <label class="form-check-label text-muted">
                        <input type="checkbox" class="form-check-input">
                        Recordarme
                        <i class="input-helper"></i>
                      </label>
                    </div>
                    <a href="javascript:void(0);" class="auth-link text-black">¿Olvidaste tu contraseña?</a>
                  </div>

                  <!--
                  <div class="mb-2">
                    <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                      <i class="mdi mdi-facebook mr-2"></i>Connect using facebook
                    </button>
                  </div>-->

                  <!--
                  <div class="text-center mt-4 font-weight-light">
                    ¿No tienes una cuenta? <router-link to="/user/register" class="text-primary">Crea una</router-link>
                  </div>
                  -->
                </form>
              </div>
            </div>
          </div>
      </div>
      <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>

</template>


<script>


import api from '@/util/api.js';
import submitbutton from '@/components/ui-feature/submit-button';

import Vue from 'vue'
  import Snotify, { SnotifyPosition} from 'vue-snotify'
  const options = {
    toast: {
      position: SnotifyPosition.rightTop,
    }
  }
  Vue.use(Snotify,options)

export default {
  data() {
    return {
      email: '',
      password: '',
      error:false,
      loading:false
    };
  },
  components:{
    submitbutton
  },
  methods: {
    login() {
      this.loading=true;
      this.error=false;
      api.post('/auth/login', {
        username: this.email,
        password: this.password,
        
      }).then(response => {


        const token = response.data.token;
        
        localStorage.setItem('access_token', token);
        localStorage.setItem('refresh_token', response.data.refresh_token);
        localStorage.setItem('user_name', response.data.name);
        localStorage.setItem('user_profile', response.data.profile);

        this.$router.push('/');
      }).catch(error => {

         console.log(error);
          this.error=true;

      }).finally(()=>{

          this.loading=false;
        
      });
    },







  },

  created(){

  }
};
</script>